import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

function Reviews() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div>
            <Carousel
                variant="dark"
                activeIndex={index}
                onSelect={handleSelect}
            >
                <Carousel.Item>
                    <div className="card_review">
                        <div className="pp_name">
                            <a href="https://www.google.com/maps/contrib/106919917077107228932/photos/@41.9005222,-87.9401842,17z/data=!3m1!4b1!4m3!8m2!3m1!1e1?hl=en-US">
                                <img
                                    class="pp_user"
                                    alt="Ghothenks 14"
                                    src="https://lh3.googleusercontent.com/a-/AFdZucoVgK9V5lkLZuM97OpZhd-Vk9bJbpJzVNw5Sy2j=s40-c-c0x00000000-cc-rp-mo-br100"
                                />
                            </a>

                            <span className="name_reviews">
                                <span className="pseudo">
                                    <a href="https://www.google.com/maps/contrib/106919917077107228932?hl=en-US&amp;sa=X&amp;ved=2ahUKEwjf5bPiwIL6AhXO0YUKHeTCDewQvvQBegQIARAj">
                                        Ghothenks 14
                                    </a>
                                </span>

                                <a
                                    class="reviews_photos"
                                    href="https://www.google.com/maps/contrib/106919917077107228932?hl=en-US&amp;sa=X&amp;ved=2ahUKEwjf5bPiwIL6AhXO0YUKHeTCDewQvvQBegQIARAx"
                                >
                                    <span class="reviews">
                                        4 reviews·3 photos
                                    </span>
                                </a>

                                <div className="notation">
                                    <img
                                        className="stars"
                                        src="./images/Google_reviews_stars.png"
                                        alt="Google notation stars"
                                    />
                                </div>

                                <div className="review_text">
                                    <p>
                                        French Cuts Brings upscale barbering and
                                        1:1 treatment to a whole new level. I
                                        would leave 1,000 stars if possible.
                                        Julien is an incredible barber who
                                        really knows his craft very well and
                                        aims for perfection after every single
                                        cut. This is the type of barber you keep
                                        for life
                                    </p>
                                </div>
                            </span>
                        </div>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div className="card_review">
                        <div className="pp_name">
                            <a href="https://www.google.com/maps/contrib/118383553495497138440?hl=en-US&amp;sa=X&amp;ved=2ahUKEwjf5bPiwIL6AhXO0YUKHeTCDewQvfQBegQIARA-">
                                <img
                                    class="pp_user"
                                    alt="matt thayer"
                                    src="https://lh3.googleusercontent.com/a/AItbvmnrj_TudlqJAHuxzrNBQxjaT_QAUZgDaDry-REd7w=s40-c-c0x00000000-cc-rp-mo-br100"
                                />
                            </a>

                            <span className="name_reviews">
                                <span className="pseudo">
                                    <a href="https://www.google.com/maps/contrib/118383553495497138440?hl=en-US&amp;sa=X&amp;ved=2ahUKEwjf5bPiwIL6AhXO0YUKHeTCDewQvvQBegQIARA_">
                                        matt thayer
                                    </a>
                                </span>

                                <a
                                    class="reviews_photos"
                                    href="https://www.google.com/maps/contrib/118383553495497138440?hl=en-US&amp;sa=X&amp;ved=2ahUKEwjf5bPiwIL6AhXO0YUKHeTCDewQvvQBegQIARBN"
                                >
                                    <span class="reviews">
                                        4 reviews·1 photos
                                    </span>
                                </a>

                                <div className="notation">
                                    <img
                                        className="stars"
                                        src="./images/Google_reviews_stars.png"
                                        alt="Google notation stars"
                                    />
                                </div>

                                <div className="review_text">
                                    <p>
                                        Been going to Julien for awhile now and
                                        he never disappoints! He absolutely
                                        crushed my wedding haircut and I will
                                        definitely be a returning customer. The
                                        new space is great and inviting and he’s
                                        a great guy to talk to. 10/10 recommend
                                    </p>
                                </div>
                            </span>
                        </div>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div className="card_review">
                        <div className="pp_name">
                            <a href="https://booksy.com/en-us/458966_french-cuts_barber-shop_122877_elmhurst#ba_s=sr_1">
                                <img
                                    class="pp_user pp_user_icon"
                                    alt="user_pp"
                                    src="./images/user_pp.png"
                                />
                            </a>

                            <span className="name_reviews">
                                <span className="pseudo">Zach Z</span>

                                <span class="reviews">Aug 7. 2022</span>

                                <div className="notation">
                                    <img
                                        className="stars"
                                        src="./images/Google_reviews_stars.png"
                                        alt="Google notation stars"
                                    />
                                </div>

                                <div className="review_text">
                                    <p>
                                        I have been going to Julien for a few
                                        years now and his cuts are always great.
                                        He is very polite and professional.
                                        Highly recommend.
                                    </p>
                                </div>
                            </span>
                        </div>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div className="card_review">
                        <div className="pp_name">
                            <a href="https://booksy.com/en-us/458966_french-cuts_barber-shop_122877_elmhurst#ba_s=sr_1">
                                <img
                                    class="pp_user pp_user_icon"
                                    alt="user_pp"
                                    src="./images/user_pp.png"
                                />
                            </a>

                            <span className="name_reviews">
                                <span className="pseudo">Shameer T</span>

                                <span class="reviews">Aug 3. 2022</span>

                                <div className="notation">
                                    <img
                                        className="stars"
                                        src="./images/Google_reviews_stars.png"
                                        alt="Google notation stars"
                                    />
                                </div>

                                <div className="review_text">
                                    <p>
                                        I have been going to Julien for a while
                                        now, wouldn't even think of going
                                        anywhere else. He really takes his time
                                        with everyone and it shows! Amazing
                                        haircuts by a true professional.
                                    </p>
                                </div>
                            </span>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default Reviews;
