import React from "react";

function Header() {
    return (
        <header className="banner">
            <section className="appointment_button">
                <button className="book_appointment">
                    <a
                        href="https://booksy.com/en-us/instant-experiences/widget/458966?instant_experiences_enabled=true&ig_ix=true"
                        className="booksy"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src="./images/Booking_Button.png"
                            alt="Book an appointment at French Cuts barber shop"
                        />
                    </a>
                </button>
            </section>

            <img
                className="logo"
                src="./images/Logo_rounded.png"
                alt="French Cuts Logo"
            />

            <section className="social_network">
                <a
                    href="https://www.facebook.com/frenchcuts26"
                    className="facebook"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src="./images/Facebook.png"
                        alt="French Cuts Facebook"
                    />
                </a>
                <a
                    href="https://www.instagram.com/french.cuts/"
                    className="instagram"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src="./images/Instagram_rounded.png"
                        alt="French Cuts Instagram"
                    />
                </a>
            </section>
        </header>
    );
}

export default Header;
