import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

function Photos() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div className="photos_carousel">
            <Carousel
                variant="dark"
                activeIndex={index}
                onSelect={handleSelect}
            >
                <Carousel.Item>
                    <img
                        className="first_photo"
                        src="./images/photos/310345759_1189786148239326_6518044249528856614_n.jpg"
                        alt="First slide"
                        loading="lazy"
                    />
                </Carousel.Item>

                <Carousel.Item>
                    <img
                        className="second_photo"
                        src="./images/photos/309798347_6183450245001905_1001924359802491610_n.jpg"
                        alt="Second slide"
                        loading="lazy"
                    />
                </Carousel.Item>

                <Carousel.Item>
                    <img
                        className="third_photo"
                        src="./images/photos/309952883_1048901762465991_2727379486612752564_n.jpg"
                        alt="Third slide"
                        loading="lazy"
                    />
                </Carousel.Item>

                <Carousel.Item>
                    <img
                        className="fourth_photo"
                        src="./images/photos/310006930_779932256630092_4664673701531659622_n.jpg"
                        alt="Fourth slide"
                        loading="lazy"
                    />
                </Carousel.Item>

                <Carousel.Item>
                    <img
                        className="fifth_photo"
                        src="./images/photos/309997843_3251391788482538_1556020444477898530_n.jpg"
                        alt="Fifth slide"
                        loading="lazy"
                    />
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default Photos;
