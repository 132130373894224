import React from "react";
import Header from "./components/Header";
import Informations from "./components/Informations";
import Photos from "./components/Photos";
import Reviews from "./components/Reviews";
import Footer from "./components/Footer";

function App() {
    return (
        <>
            <Header />
            <Informations />
            <Photos />
            <Reviews />
            <Footer />
        </>
    );
}

export default App;
