import React from "react";

function Informations() {
    return (
        <section className="location_description">
            <p className="location">
                120 N.York St. | Suite 201 | Elmhurst, IL 60126
            </p>
            <p className="description">
                Traditional barbering with a modern twist
            </p>
            <section className="join_me">
                <a className="call_me" href="tel:3123180141">
                    <p>Call me</p>
                </a>

                <a className="email_me" href="mailto:frenchcuts26@gmail.com">
                    <p>Email me</p>
                </a>
            </section>
        </section>
    );
}

export default Informations;
