import React from "react";

function Footer() {
    return (
        <footer>
            <section className="hours_location">
                <table className="hours">
                    <tbody>
                        <tr>
                            <td>Monday</td>
                            <td>Closed</td>
                        </tr>
                        <tr>
                            <td>Tuesday</td>
                            <td>9am - 6pm</td>
                        </tr>
                        <tr>
                            <td>Wednesday</td>
                            <td>9am - 6pm</td>
                        </tr>
                        <tr>
                            <td>Thursday</td>
                            <td>9am - 6pm</td>
                        </tr>
                        <tr>
                            <td>Friday</td>
                            <td>9am - 6pm</td>
                        </tr>
                        <tr>
                            <td>Saturday</td>
                            <td>8am - 4pm</td>
                        </tr>
                        <tr>
                            <td>Sunday</td>
                            <td>Closed</td>
                        </tr>
                    </tbody>
                </table>
                <section className="google_maps">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2969.6435592208163!2d-87.94237824864256!3d41.9005221791184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x954980d6bc186cd%3A0x259baaebc9917ab8!2sFrench%20Cuts!5e0!3m2!1sfr!2sfr!4v1662475630907!5m2!1sfr!2sfr"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        title="French Cuts on Google Maps"
                    ></iframe>
                </section>
            </section>
            <p className="otr">
                <a
                    href="https://otr-portfolio.vercel.app/en"
                    target="_blank"
                    rel="noreferrer"
                >
                    Developed by OtR
                </a>
            </p>
        </footer>
    );
}

export default Footer;
